import React, { useEffect, useState } from 'react'
import Layout from '../components/layout-2'
import { Amplify, API, Auth, graphqlOperation, Hub } from 'aws-amplify'
import awsconfic from '../aws-exports'
import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { Link } from 'gatsby'
import { GoCardlessAuthorization } from '../types/gocardless-authorization'
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'

Amplify.configure(awsconfic)

interface Budget {
  name?: string
}

interface budgetC {
  name: Budget
}

const Subscription = ({ signOut, user, _serverData, location }) => {
  const [userLoggedIn, setUserLoggedIn] = useState(!!user?.username)
  const [initFlow, setInitFlow] = useState<GoCardlessAuthorization>()


  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signIn') {
        setUserLoggedIn(true)
      }
    })

  })

  useEffect(() => {
    if (user) {
      /*
      getGocardlessInit()
        .then(res => setInitFlow(res))

       */
    }
  }, [user])


  return (
    <Layout path="/subscribe" userLoggedIn={userLoggedIn}>
      <div className="h-96">
        <div className="items-center mx-20">
          <h2 className="text-center font-semibold text-2xl text-gray-700">Welcome to budgetsyncer</h2>
          <p className="mt-4 text-gray-700 text-center">We want to keep your budgeting experience as seamless as
            possible,
            and that includes ensuring reliable transaction fetching. To cover the costs associated with this service,
            BudgetSyncer requires a modest fee to run smoothly. We believe it's a small investment for the convenience
            and
            time-saving benefits it provides. Your support helps us continue providing top-notch service and innovation
            in
            budget management. Thank you for being a part of our community!</p>
        </div>
      </div>
      <PayPalScriptProvider
        options={{
          clientId: 'AZ-DBvIDAS6Cq-OooHNuRSdyEY2lz567OTY82VbFDeGZF_Tr6azDVMePBbJiwIrmEonbR37GNGjXPkvU',
          components: 'buttons',
        }}>
        <Wrapper></Wrapper>
      </PayPalScriptProvider>
    </Layout>
  )
}
const Wrapper = () => {
  const [{ options }, dispatch] = usePayPalScriptReducer()
  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        intent: 'subscription',
      },
    })
  }, [])
  return (

    <PayPalButtons
      createSubscription={(data, actions) => {
        return actions.subscription
          .create({
            plan_id: 'P-6VR20737NN77260175OYNBII',
          })
          .then((orderId) => {
            // Your code here after create the order
            console.log('orderId', orderId)
            return orderId
          })
      }}
      style={{
        label: 'subscribe',
      }}
    />

  )
}

export default withAuthenticator(Subscription)
